import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";

import styles from "./index.module.css";

const mobileW = 800;

const Hero = ({
  translations: { title, description, description2 },
  image,
  color,
}) => {
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <div className={styles.title}>
          <Typography weight="medium" variant="h1" color={color}>
            {title}
          </Typography>
        </div>
        <div className={styles.description}>
          <Typography color="black">{description}</Typography>
        </div>
        {description2 ? (
          <div className={styles.description}>
            <Typography color="black">{description2}</Typography>
          </div>
        ) : null}
      </div>
      <div className={styles.right}>
        <Img
          className={styles.image}
          alt="hero"
          fluid={image.childImageSharp.fluid}
          objectFit="cover"
        />
        <div className={styles.grid}>
          <Grid
            columns={dimensions.width > mobileW ? 27 : 18}
            lines={18}
            color={color}
            opacity={0.5}
          />
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  translations: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    description2: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape().isRequired,
  color: PropTypes.oneOf(["purple", "red", "green"]).isRequired,
};

export default Hero;
