import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import Typography from "root/components/Typography";
import AskSioslifeForm from "root/components/AskSioslifeForm";

import styles from "./index.module.css";

const mobileW = 800;

const ProductKnowMore = ({ translations, color, image }) => {
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div>
          <div className={styles.title}>
            <Typography weight="medium" variant="h2" color={color}>
              {translations.title}
            </Typography>
          </div>

          <div className={styles.contact}>
            <AskSioslifeForm
              translations={translations.askSioslifeForm}
              color={color}
            />
          </div>
        </div>
        <div className={styles.imageContainer}>
          <Img
            className={styles.image}
            alt="product know more"
            fluid={image.childImageSharp.fluid}
            objectFit="cover"
          />
          <div className={styles.grid}>
            <Grid
              columns={dimensions.width > mobileW ? 36 : 18}
              lines={18}
              color={color}
              opacity={0.5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProductKnowMore.propTypes = {
  color: PropTypes.oneOf(["purple", "red", "green", "blue"]).isRequired,
  translations: PropTypes.shape().isRequired,
  image: PropTypes.shape().isRequired,
};

export default ProductKnowMore;
