import React from "react";
import PropTypes from "prop-types";
import SearchInstitution from "root/components/SearchInstitution";

import styles from "./index.module.css";

const ProductInstitutions = ({
  translations: { findInstitution, askSioslifeForm },
  color,
}) => {
  return (
    <div className={styles.root}>
      <SearchInstitution
        findInstitution={findInstitution}
        askSioslifeForm={askSioslifeForm}
        color={color}
        page="product"
      />
    </div>
  );
};

ProductInstitutions.propTypes = {
  color: PropTypes.oneOf(["purple", "red", "green", "blue"]).isRequired,
  translations: PropTypes.shape({
    findInstitution: PropTypes.shape({
      findInstitutionTitle: PropTypes.string.isRequired,
      searchPlaceholder: PropTypes.string.isRequired,
      institutionSiolife: PropTypes.string.isRequired,
      institutionNotSiolife: PropTypes.string.isRequired,
      institutionNotSiolifeQuestion: PropTypes.string.isRequired,
    }).isRequired,
    askSioslifeForm: PropTypes.shape({
      namePlaceholder: PropTypes.string.isRequired,
      emailPlaceholder: PropTypes.string.isRequired,
      phonePlaceholder: PropTypes.string.isRequired,
      institutionNamePlaceholder: PropTypes.string.isRequired,
      institutionPositionPlaceholder: PropTypes.string.isRequired,
      submit: PropTypes.string.isRequired,
    }).isRequired,
    askDemoSimpleForm: PropTypes.shape({
      askDemoQuestion: PropTypes.string.isRequired,
      emailPlaceholder: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ProductInstitutions;
