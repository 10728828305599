import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Typography from "root/components/Typography";
import Grid from "root/components/Grid";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import Wave from "root/components/SvgColor/Wave";
import Wellchair from "root/components/SvgColor/Wellchair";

import styles from "./index.module.css";

const mobileW = 800;

const ProductValuePropositions = ({
  translations,
  phoneImage,
  image,
  color,
}) => {
  const dimensions = useWindowDimensions();

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography color={color} weight="medium" variant="h3">
              {translations.subtitle1}
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography color="black">{translations.description1}</Typography>
          </div>
          <div className={styles.title}>
            <Typography color={color} weight="medium" variant="h3">
              {translations.subtitle2}
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography color="black">{translations.description2}</Typography>
          </div>
        </div>
        <div className={styles.right}>
          <Img
            className={styles.phoneImage}
            alt="hero"
            fluid={phoneImage.childImageSharp.fluid}
            objectFit="cover"
          />
          <div className={styles.wave}>
            <Wave color={color} />
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.left}>
          <Img
            className={styles.image}
            alt="hero"
            fluid={image.childImageSharp.fluid}
            objectFit="cover"
          />
          <div className={styles.grid}>
            <Grid
              columns={dimensions.width > mobileW ? 18 : 18}
              lines={dimensions.width > mobileW ? 27 : 18}
              color={color}
              opacity={0.5}
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.title}>
            <Typography color={color} weight="medium" variant="h3">
              {translations.subtitle3}
            </Typography>
          </div>
          <div className={styles.description}>
            <Typography color="black">{translations.description3}</Typography>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <Typography color={color} weight="medium" variant="h3">
          {translations.featuresTitle}
        </Typography>
        <div className={styles.features}>
          <div className={styles.featureContainer}>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                ☎️
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title1}
                </Typography>
                <Typography color="black">
                  {translations.features.description1}
                </Typography>
              </div>
            </div>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                📷
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title2}
                </Typography>
                <Typography color="black">
                  {translations.features.description2}
                </Typography>
              </div>
            </div>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                📰
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title3}
                </Typography>
                <Typography color="black">
                  {translations.features.description3}
                </Typography>
              </div>
            </div>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                🎨
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title4}
                </Typography>
                <Typography color="black">
                  {translations.features.description4}
                </Typography>
              </div>
            </div>
          </div>

          <div className={styles.featureContainer}>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                🎬
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title5}
                </Typography>
                <Typography color="black">
                  {translations.features.description5}
                </Typography>
              </div>
            </div>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                🎼
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title6}
                </Typography>
                <Typography color="black">
                  {translations.features.description6}
                </Typography>
              </div>
            </div>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                🎲
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title7}
                </Typography>
                <Typography color="black">
                  {translations.features.description7}
                </Typography>
              </div>
            </div>
            <div className={styles.feature}>
              <span className={styles.emoji} role="img" aria-label="telephone">
                🙏
              </span>
              <div>
                <Typography color="black" weight="medium">
                  {translations.features.title8}
                </Typography>
                <Typography color="black">
                  {translations.features.description8}
                </Typography>
              </div>
            </div>
          </div>
          <div className={styles.wellchair}>
            <Wellchair color={color} />
            <div className={styles.grid}>
              <Grid columns={18} lines={18} color={color} opacity={0.5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProductValuePropositions.propTypes = {
  translations: PropTypes.shape().isRequired,
  color: PropTypes.oneOf(["purple", "red", "green"]).isRequired,
  phoneImage: PropTypes.shape().isRequired,
  image: PropTypes.shape().isRequired,
};

export default ProductValuePropositions;
