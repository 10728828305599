import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./index.module.css";

const Wave = ({ color }) => {
  const className = classNames(styles.wave, styles[color]);

  return (
    <svg
      width="435"
      height="535"
      viewBox="0 0 435 535"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.710708 0.0794988C0.713401 0.0436893 0.71608 0.0083189 100.43 7.56104C200.145 15.1138 200.147 15.0793 200.15 15.0452L200.155 14.9791L200.164 14.8542C200.17 14.7741 200.175 14.7004 200.18 14.6331C200.189 14.4984 200.197 14.389 200.202 14.3044C200.212 14.1555 200.216 14.0826 200.216 14.0826L200.215 14.0867C200.213 14.1328 200.192 14.5474 200.172 15.2941C200.132 16.7983 200.1 19.5747 200.226 23.3414C200.482 31.0131 201.367 41.983 203.832 54.2467C209.025 80.0864 219.353 102.958 236.213 118.039C240.854 122.191 249.084 128.122 258.991 132.135C268.329 135.918 277.441 137.371 286.783 135.991C301.423 133.828 312.636 128.66 319.883 124.319C323.471 122.169 325.838 120.367 326.845 119.556C327.162 119.3 327.324 119.16 327.324 119.16C327.324 119.16 327.276 119.202 327.179 119.289C327.041 119.414 326.801 119.634 326.457 119.962C326.285 120.125 326.087 120.316 325.862 120.536C325.75 120.646 325.631 120.763 325.505 120.887C325.442 120.95 325.377 121.014 325.311 121.08L325.159 121.231C325.133 121.257 325.107 121.283 395.828 191.984C466.548 262.684 466.522 262.711 466.496 262.737L466.442 262.791L466.332 262.9C466.257 262.974 466.18 263.051 466.101 263.129C465.942 263.287 465.773 263.453 465.594 263.627C465.237 263.976 464.84 264.359 464.405 264.774C463.534 265.604 462.504 266.563 461.318 267.635C458.949 269.778 455.938 272.385 452.298 275.317C445.042 281.161 435.133 288.418 422.668 295.886C397.809 310.779 361.808 327.078 316.013 333.844C213.984 348.917 136.787 297.442 102.871 267.103C41.8801 212.545 17.6527 142.911 7.75295 93.656C2.67123 68.3721 0.875419 46.1468 0.337058 30.0103C0.0655665 21.8729 0.107795 15.0918 0.241859 10.0147C0.309036 7.47072 0.399703 5.33863 0.487289 3.66074C0.531105 2.82128 0.574241 2.09391 0.613426 1.48387C0.633026 1.17879 0.651641 0.902884 0.668884 0.656794C0.677505 0.533742 0.685783 0.418126 0.693664 0.310026L0.705184 0.153514L0.710708 0.0794988Z"
        className={className}
      />
    </svg>
  );
};

Wave.propTypes = {
  color: PropTypes.oneOf(["purple", "red", "green"]).isRequired,
};

export default Wave;
