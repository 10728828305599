import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.css";

const Wellchair = ({ color }) => {
  const className = classNames(styles.wellchair, styles[color]);

  return (
    <svg
      width="380"
      height="453"
      viewBox="0 0 380 453"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.92529 367.407C9.92529 367.407 38.6804 384.133 61.0415 371.314C83.4026 358.494 42.9029 238.596 85.2549 226.177C127.607 213.757 181.374 238.778 189.545 195.119M56.3362 408.619C56.3362 408.619 97.6639 430.336 114.209 397.903C130.755 365.471 94.2504 295.265 129.718 276.902C165.186 258.54 264.131 332.355 250.239 162.066"
        className={className}
        strokeWidth="6"
      />
      <path
        d="M158.205 325.923C158.205 386.342 207.184 435.321 267.603 435.321C328.021 435.321 377 386.342 377 325.923C377 273.247 339.77 229.267 290.188 218.86"
        className={className}
        strokeWidth="6"
      />
      <path
        d="M50.7773 69.3442C50.7773 69.3442 73.7199 61.7147 103.821 79.4202C133.921 97.1256 166.497 160.24 216.5 129.74C266.504 99.2393 287.45 120.214 309.586 100.65C331.722 81.0854 325.68 38.3657 325.68 38.3657M206.746 3C206.746 3 165.043 38.4895 206.746 74.9786"
        className={className}
        strokeWidth="6"
      />
    </svg>
  );
};

Wellchair.propTypes = {
  color: PropTypes.oneOf(["purple", "red", "green", "yellow"]).isRequired,
};

export default Wellchair;
